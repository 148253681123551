import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SanitySection } from 'components';

const Page = (props, { location }) => {
  const { sanityPage } = props.data;
  const { sections: pageSections, seoDescription, seoKeywords, parentPage, seoTitle, slug } = sanityPage;

  const seo = {
    title: seoTitle || '',
    desc: seoDescription || '',
  };

  return (
    <Layout location={location} seo={seo}>
      {pageSections.map((section) => (
        <SanitySection data={section} slug={slug?.current} key={section._key} />
      ))}
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query Page($id: String!) {
    sanityPage(id: { eq: $id }) {
      seoDescription
      seoTitle
      seoKeywords
      slug {
        current
      }
      title
      id
      sections {
        #

        ... on SanityForm {
          _key
          _type
          title
          contactOptions
        }

        #

        ... on SanityFaq {
          _key
          _type
          title
          tileBG
          subtitle
          faqElements {
            answer
            question
          }
        }

        #

        ... on SanityLatestArticles {
          _key
          _type
          subHeading
          heading
          buttonText
          buttonUrl {
            slug {
              current
            }
          }
        }

        #

        ... on SanitySalesforceForm {
          _key
          _type
          title
          formHtml
        }

        #

        ... on SanityBoxes {
          _key
          _type
          boxes {
            ... on SanityBox {
              _key
              _type
              imageSize
              heading
              icon {
                name
                provider
              }
              image {
                asset {
                  url
                  gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
                }
              }
              subheading
            }
            ... on SanitySimpleBox {
              _key
              _type
              icon {
                asset {
                  url
                  gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
                }
              }
            }
          }
          title
          tileBG
          subtitle
          boxType
          link {
            ... on SanityPost {
              id
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
           
          }
          linkText
        }

        #

        ... on SanityTitleLogo {
          _key
          _type
          title
          image {
            asset {
              url
              gatsbyImageData(layout: CONSTRAINED, width: 1000, placeholder: BLURRED)
            }
          }
          titleLink {
            ... on SanityPost {
              id
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
          }
          imageLink {
            ... on SanityPost {
              id
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
          }
        }

        #

        ... on SanityTestimonials {
          _key
          _type
          title
          tileBG
          subtitle
          buttonText
          buttonLink {
            ... on SanityCategory {
              id
              title
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
          }
          reviews {
            review {
              title
              review
              rating
              name
              image {
                asset {
                  gatsbyImageData(layout: CONSTRAINED, width: 210, placeholder: BLURRED)
                  url
                }
              }
            }
          }
        }

        #

        ... on SanityTeam {
          _key
          _type
          viewAllRoute {
            slug {
              current
            }
          }
          viewAllButtonText
          title
          tileBG
          subtitle
          person {
            person {
              title
              name
              bio
              image {
                asset {
                  gatsbyImageData(layout: CONSTRAINED, width: 500, placeholder: BLURRED)
                  url
                }
              }
            }
          }
        }

        #

        ... on SanityServicesSection {
          _key
          _type
          title
          subtitle
          services {
            title
            link {
              ... on SanityPost {
                id
                slug {
                  current
                }
              }
              ... on SanityPage {
                id
                slug {
                  current
                }
              }
              ... on SanityCategory {
                id
                title
              }
            }
            icon {
              provider
              name
            }
          }
        }

        #

        ... on SanityPosts {
          _key
          _type
          useRecentPosts
          title
          tileBG
          subtitle
          category {
            title
          }
        }

        #

        ... on SanityHero {
          _key
          _type
          homePage
          title
          subtitle
          solid
          buttonText
          buttonLink {
            ... on SanityPage {
              id
              title
              slug {
                current
              }
            }
            ... on SanityCategory {
              id
              title
            }
          }
          breadCrumbs
          parentPage {
            ... on SanityPage {
              id
              title
              slug {
                current
              }
            }
          }
          backgroundImage {
            asset {
              url
              gatsbyImageData(layout: CONSTRAINED, width: 1000, placeholder: BLURRED)
            }
          }
          ... on SanityHero {
            _key
            _type
            services {
              title
              subtitle
              icon {
                provider
                name
              }
              services {
                title
                subtitle
                icon {
                  provider
                  name
                }
                link {
                  ... on SanityPost {
                    id
                    slug {
                      current
                    }
                    title
                  }
                  ... on SanityPage {
                    id
                    slug {
                      current
                    }
                    title
                  }
                }
              }
            }
          }
        }

        #

        ... on SanityContentSide {
          _key
          _type
          video
          title
          tileBG
          subtitle
          ImageRight
          _rawRichText(resolveReferences: { maxDepth: 8 })
          columnTitle
          externalButtonLink
          imageLink {
            internalLink {
              ... on SanityPage {
                id
                slug {
                  current
                }
              }
            }
            externalLink
          }
          externalButtonLinkTwo
          buttonTextTwo
          buttonText
          buttonLinkTwo {
            ... on SanityPdfDocument {
              id
              pdfFile {
                asset {
                  url
                }
              }
            }
            ... on SanityPage {
              id
              slug {
               current
              }
            }
          }
          buttonLink {
            ... on SanityPage {
              id
              title
              slug {
                current
              }
            }
            ... on SanityCategory {
              id
              title
            }
            ... on SanityPdfDocument {
              id
              pdfFile {
                asset {
                  url
                }
              }
            }
          }

          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 1000, placeholder: BLURRED)
            }
          }
          iconTextSubtitle {
            title
            subtitle
            icon {
              provider
              name
            }
          }
          buttonText
        }

        #

        ... on SanityContentCentre {
          _key
          _type
          title
          tileBG
          subtitle
          _rawRichText(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  }
`;
